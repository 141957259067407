import React, { useState } from "react";
import { Badge, Button } from "react-bootstrap";
import { UserListdetail } from "../../../models/User";
import { lupaService } from "../../../services/lupa";
import { deleteUser } from "../../../services/user";
import { getRoleNameFromRole } from "../../../utils/common";
import { toastTypes } from "../../../utils/toastTypes";
import {useNavigate} from "react-router-dom";

type Props = {
  item: UserListdetail;
  index: number;
  setShowUpdate: (value: {
    show: boolean;
    user: UserListdetail | undefined;
  }) => void;
  setShowDelete: (value: { show: boolean; id: number | undefined }) => void;
};

function TableRow({
  item,
  index,
  setShowUpdate,
  setShowDelete,
}: Props): JSX.Element {
  const isTracking = (claims: any) => {
    if (claims.length > 0) {
      return true;
    } else {
      return false;
    }
  };
const navigate = useNavigate();
  return (
    <tr key={index}>
      <td>{index + 1}</td>
      <td>
        {item.firstName + " " + item.lastName}
        {!item.isActive && (
          <Badge bg="danger" className="ms-2">
            Inactive
          </Badge>
        )}
      </td>
      <td>{item.email}</td>
      <td>{item.role[0]}</td>
      {/* <td align="center">
        {isTracking(item.UserClaims) ? (
          <i className="bi bi-check-circle-fill text-success"></i>
        ) : (
          <i className="bi bi-x-circle-fill text-danger"></i>
        )}
      </td> */}
      {/* <td align="center">
        {item.awbCount ? (
          <span className="badge bg-success">{item.awbCount}</span>
        ) : (
          <span className="badge bg-danger">0</span>
        )}
      </td> */}
      {/* <td align="center">
        {" "}
        {item.UserClaims.length > 0 ? (
          <span className="badge bg-success">
            {item.UserClaims[0]?.monthlyClaimLimit}
          </span>
        ) : (
          <span className="badge bg-danger">0</span>
        )}
      </td> */}
      <td align="center">
        <Button
          className="btn btn-primary"
          variant="primary"
          size="sm"
          onClick={() => setShowUpdate({ show: true, user: item })}
        >
          <i className="bi bi-pencil"></i>
        </Button>
        {item.isActive ? (
          <Button
            className="btn btn-danger ms-2"
            variant="danger"
            size="sm"
            onClick={() => setShowDelete({ show: true, id: item.id })}
          >
            <i className="bi bi-trash"></i>
          </Button>
        ) : (
          <Button
            className="btn btn-success ms-2"
            variant="success"
            size="sm"
            onClick={() => ({ show: true, id: item.id })}
          >
            <i className="bi bi-check-circle"></i>
          </Button>

        )}
          <Button
              className="btn btn-primary ms-2"
              variant="primary"
              size="sm"
              onClick={() =>navigate("/user_airline_mapping/"+item.id) }
          >
              <i className="bi bi-airplane"></i>
          </Button>
      </td>
    </tr>
  );
}

export default TableRow;
