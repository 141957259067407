import React, { useMemo, useState } from 'react';
import {
    MaterialReactTable,
    // createRow,
    type MRT_ColumnDef,
    type MRT_Row,
    type MRT_TableOptions,
    useMaterialReactTable,
} from 'material-react-table';
import {Box, Button, IconButton, MenuItem, Tooltip} from '@mui/material';
import {
    QueryClient,
    QueryClientProvider,
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {lupaService} from "../../../services/lupa";
import ClientNavbar from "../../foundation/Navbar";
import {useParams} from "react-router-dom";
export type UserAirlineMapping = {
    id: string;
    userId: number;
    preferedDataSourceId1: number
    preferedDataSourceId2: number;
    airlineMappingId: string;
};
export type AvailableAirline = {
    id: string;
    airlineCode: string;
    name: string;
    dataSource: number
};
export type DataSource = {
    id: number;
    name: number;
};

const Example = () => {

    const [validationErrors, setValidationErrors] = useState<
        Record<string, string | undefined>
    >({});
    const { data: dataSourceOptions = []} = useGetDataSourceOptions();
    const [isAdding, setIsAdding] = useState<boolean>(false);
    const [editingRowId, setEditingRowId] = useState<string | null>(null); // Track the editing row ID
    const { data: airlineMappingOptions = [] } = useGetAirlineMappingOptions();
    const [editableColumns, setEditableColumns] = React.useState({
        airlineMappingId: false,
    });

    const validateRow = (values: UserAirlineMapping): boolean => {
        const errors: Record<string, string> = {};

        // List all keys except the nullable column
        const requiredColumns = ['airlineMappingId', 'preferedDataSourceId1', 'preferedDataSourceId2'];

        // Check for null or empty values
        requiredColumns.forEach((key) => {
            if (!values[key as keyof UserAirlineMapping]) {
                errors[key] = `${key} is required`;
            }
        });

        // Check if preferedDataSourceId1 and preferedDataSourceId2 are the same
        if (
            values.preferedDataSourceId1 &&
            values.preferedDataSourceId2 &&
            values.preferedDataSourceId1 === values.preferedDataSourceId2
        ) {
            errors.preferedDataSourceId2 = 'Data Source 1 and Data Source 2 cannot be the same';
        }

        setValidationErrors(errors);
        return Object.keys(errors).length === 0; // Valid if no errors
    };


    // @ts-ignore
    const columns = useMemo<MRT_ColumnDef<UserAirlineMapping>[]>(
        () => [
            {
                accessorKey: 'id', // Optional, just for reference
                header: 'Id',
                enableEditing: false, // Prevent editing this column
            },

            {
                accessorKey: 'airlineMappingId', // field name
                header: 'Airline Mapping',
                enableEditing: editableColumns.airlineMappingId,
                muiEditTextFieldProps: ({row}) => ({
                    select: true,
                    error: isAdding?!!validationErrors['airlineMappingId']:false, // Show error for column 2 if any
                    helperText: isAdding?validationErrors['airlineMappingId']:false,
                    children: airlineMappingOptions
                        .filter(
                            (airline:AvailableAirline) =>
                                !fetchedUsers.some(
                                    (user) => user.airlineMappingId === airline.id
                                )
                        ).map((airlineMapping: AvailableAirline) => (
                        <MenuItem key={airlineMapping.id} value={airlineMapping.id}>
                            {airlineMapping.name} {/* Display name, but value is the id */}
                        </MenuItem>
                    )),
                }),
                Cell: ({ cell }) => {
                    const airlineMapping = airlineMappingOptions.find((airline: AvailableAirline) => airline.id === cell.getValue());
                    return airlineMapping ? airlineMapping.name : ''; // Show the name instead of the id
                },
            },

            {
                accessorKey: 'preferedDataSourceId1', // field name
                header: 'Data Source 1',
                editVariant: 'select',
                muiEditTextFieldProps: ({ row }) => ({
                    select: true,
                    error: !!validationErrors['preferedDataSourceId1'], // Show error for column 2 if any
                    helperText: validationErrors['preferedDataSourceId1'],
                    children: dataSourceOptions.map((dataSource: DataSource) => (
                        <MenuItem key={dataSource.id} value={dataSource.id}>
                            {dataSource.name} {/* Display name, but value is the id */}
                        </MenuItem>
                    )),
                }),
                Cell: ({ cell }) => {
                    const dataSource1 = dataSourceOptions.find((dataSource: DataSource) => dataSource.id == cell.getValue());
                    return dataSource1 ? dataSource1.name : ''; // Show the name instead of the id
                },
            },
            {
                accessorKey: 'preferedDataSourceId2', // field name
                header: 'Data Source 2',
                editVariant: 'select',
                muiEditTextFieldProps: ({ row }) => ({
                    select: true,
                    error: !!validationErrors['preferedDataSourceId2'], // Show error for column 2 if any
                    helperText: validationErrors['preferedDataSourceId2'],
                    children: dataSourceOptions.map((dataSource: DataSource) => (
                        <MenuItem key={dataSource.id} value={dataSource.id}>
                            {dataSource.name} {/* Display name, but value is the id */}
                        </MenuItem>
                    )),
                }),
                // For displaying the name in the table view (when not in edit mode)
                Cell: ({ cell }) => {
                    const dataSource2 = dataSourceOptions.find((dataSource: DataSource) => dataSource.id == cell.getValue());
                    return dataSource2 ? dataSource2.name : ''; // Show the name instead of the id
                },
            },

        ],
        [airlineMappingOptions,dataSourceOptions,validationErrors,editableColumns,isAdding],
    );

    //call CREATE hook
    const { mutateAsync: createUser, isPending: isCreatingUser } =
        useCreateUser();
    //call READ hook
    const {
        data: fetchedUsers = [],
        isError: isLoadingUsersError,
        isFetching: isFetchingUsers,
        isLoading: isLoadingUsers,
    } = useGetUsers();
    //call UPDATE hook
    const { mutateAsync: updateUser, isPending: isUpdatingUser } =
        useUpdateUser();
    //call DELETE hook
    const { mutateAsync: deleteUser, isPending: isDeletingUser } =
        useDeleteUser();

    //CREATE action
    const handleCreateUser: MRT_TableOptions<UserAirlineMapping>['onCreatingRowSave'] = async ({
                                                                                     values,
                                                                                     table,
                                                                                 }) => {
        if (!validateRow(values)) return;
        await createUser(values);
        setEditableColumns((prev) => ({
            ...prev,
            airlineMappingId: false, // Enable editing for the "Name" column
        }));
        setIsAdding(false);
        setValidationErrors({});
        table.setCreatingRow(null); //exit creating mode
    };

    //UPDATE action
    const handleSaveUser: MRT_TableOptions<UserAirlineMapping>['onEditingRowSave'] = async ({
                                                                                  values,
                                                                                  table,
                                                                              }) => {
        if (!validateRow(values)) return;

        await updateUser(values);
        setEditingRowId(null);
        setValidationErrors({});

        table.setEditingRow(null); //exit editing mode
    };

    //DELETE action
    const openDeleteConfirmModal = (row: MRT_Row<UserAirlineMapping>) => {
        if (window.confirm('Are you sure you want to delete this mapping?')) {
            deleteUser(row.original.id);
        }
    };

    const table = useMaterialReactTable({
        columns,
        data: fetchedUsers,
        createDisplayMode: 'row', // ('modal', and 'custom' are also available)
        editDisplayMode: 'row', // ('modal', 'cell', 'table', and 'custom' are also available)
        enableEditing: true,
        getRowId: (row) => row.id,
        muiToolbarAlertBannerProps: isLoadingUsersError
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        muiTableContainerProps: {
            sx: {
                minHeight: '500px',
            },
        },
        onCreatingRowCancel: () => {
            setValidationErrors({});
            setEditableColumns((prev) => ({
                ...prev,
                airlineMappingId: false, // Enable editing for the "Name" column
            }));
            setIsAdding(false);
        },
        onCreatingRowSave: handleCreateUser,
        onEditingRowCancel: () => {setValidationErrors({})
            setEditingRowId(null);},
        onEditingRowSave: handleSaveUser,
        renderRowActions: ({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Tooltip title="Edit">
                    <IconButton
                        onClick={() => {
                            if (!isAdding) {
                                setEditingRowId(row.id);
                                table.setEditingRow(row);
                            }
                        }}
                        disabled={isAdding} // Disable if adding or another row is being edited
                    >
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton color="error"
                                onClick={() => openDeleteConfirmModal(row)}
                                disabled={isAdding}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
        renderTopToolbarCustomActions: ({ table }) => (
            <Button
                variant="contained"
                onClick={() => {
                    setIsAdding(true)
                    table.setCreatingRow(true);
                    setEditableColumns((prev) => ({
                        ...prev,
                        airlineMappingId: true, // Enable editing for the "Name" column
                    }));

                }}
                disabled={isAdding || editingRowId !== null} // Disable Add button during editing
            >
                Add Mapping
            </Button>
        ),
        state: {
            isLoading: isLoadingUsers,
            isSaving: isCreatingUser || isUpdatingUser || isDeletingUser,
            showAlertBanner: isLoadingUsersError,
            showProgressBars: isFetchingUsers,
        },
    });

    return <MaterialReactTable table={table} />;
};

function useGetDataSourceOptions() {
    return useQuery({
        queryKey: ['dataSourceOptions'],
        queryFn: async () => {
            const response = await lupaService.getAvailableDataSources();
            //console.log(response.data);

            if (!response || !response.data) {
                throw new Error("Failed to fetch Available DataSource");
            }

            return response.data;
        },
        refetchOnWindowFocus: false,
    });
}

// Hook to fetch airline mapping options
function useGetAirlineMappingOptions() {
    return useQuery({
        queryKey: ['airlineMappingOptions'],
        queryFn: async () => {
            const response = await lupaService.getAvailableAirlines();
            console.log(response.data.availableAirlines);
            if (!response || !response.data) {
                throw new Error("Failed to fetch User Airline Mapping");
            }

            return response.data.availableAirlines;
        },
        refetchOnWindowFocus: false,
    });
}

function useGetUsers() {
    const { userId } = useParams();
    const queryClient = useQueryClient();
    return useQuery<UserAirlineMapping[]>({
        queryKey: ['userAirlineMapping'],
        queryFn: async () => {
            const response = await lupaService.getUserAirlineMapping(userId);
            //console.log(response.data);
            if (!response || !response.data) {
                throw new Error("Failed to fetch User Airline Mapping");
            }

            return response.data;
        },
        refetchOnWindowFocus: false,
    });
}
function useCreateUser() {

    const { userId } = useParams();
    const queryClient = useQueryClient();
    return useMutation<UserAirlineMapping, Error, UserAirlineMapping>({
        mutationFn: async (userAirlineMapping: UserAirlineMapping) => {
            // Add userId to the payload
            if (typeof userId === "string") {
                userAirlineMapping.userId = parseInt(userId, 10);
            }

            // Make the API call
            const response = await lupaService.postUserAirlineMapping(userAirlineMapping);

            // Check if the response is OK
            if (!response) {
                throw new Error("Failed to create User Airline Mapping");
            }

            // Parse and return the response as UserAirlineMapping
            // @ts-ignore
            const data = await response.data;
            return data as UserAirlineMapping;
        },

        onSuccess: (newUserInfo: UserAirlineMapping) => {
            // @ts-ignore
            queryClient.invalidateQueries(['userAirlineMapping']);
        },

    });

}

//UPDATE hook (put user in api)
function useUpdateUser() {
    const { userId } = useParams();

    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (userAirlineMapping: UserAirlineMapping) => {
            //send api update request here
            // @ts-ignore
            userAirlineMapping.userId=parseInt(userId,10)
            const response = await lupaService.patchUserAirlineMapping(userAirlineMapping)
            if (!response) {
                throw new Error("Failed to fetch User Airline Mapping");
            }
            return response;
        },
        //client side optimistic update
        onMutate: (newUserInfo: UserAirlineMapping) => {
            queryClient.setQueryData(['userAirlineMapping'], (prevUsers: any) =>
                prevUsers?.map((prevUser: UserAirlineMapping) =>
                    prevUser.id === newUserInfo.id ? newUserInfo : prevUser,
                ),
            );
        },
        // onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
    });
}

//DELETE hook (delete user in api)
function useDeleteUser() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (userAirlineMappingId: string) => {
            //send api update request here
            const response = await lupaService.deleteUserAirlineMapping(userAirlineMappingId)
            if (!response) {
                throw new Error("Failed to fetch User Airline Mapping");
            }
            return response;
        },
        //client side optimistic update
        onMutate: (userAirlineMappingId: string) => {
            queryClient.setQueryData(['userAirlineMapping'], (prevUsers: any) =>
                prevUsers?.filter((userAirlineMapping: UserAirlineMapping) => userAirlineMapping.id !== userAirlineMappingId),
            );
        },
        // onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
    });
}

const queryClient = new QueryClient();

const ExampleWithProviders = () => (
    //Put this with your other react-query providers near root of your app
    <QueryClientProvider client={queryClient}>
        <ClientNavbar />
        <Example />
    </QueryClientProvider>
);

export default ExampleWithProviders;




