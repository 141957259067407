interface Config {
  url: {
    API_URL: string;
    APP_URL: string;
    SCRAP_URL: string;
    MICRO_URL: string;
  };
}
const local: Config = {
  url: {
    API_URL: "http://localhost:3000/",
    APP_URL: "http://localhost:3001/",
    SCRAP_URL: "http://localhost:3001/",
    MICRO_URL: "http://localhost:1234/",
  },
};

const development: Config = {
  //     url: {
  //         API_URL: "http://172.29.104.61/foo/",
  //         APP_URL: "http://172.29.104.61/bar/",

  //     },

  url: {
    API_URL: "http://localhost:3000/",
    APP_URL: "http://localhost:3001/",
    SCRAP_URL: "https://devapi.lupaair.com/",
    MICRO_URL: "http://localhost:1234/",
  },
};

const staging: Config = {
  url: {
    API_URL: "https://demo.api.air.intemo.tech/",
    APP_URL: "https://demo.app.intemo.tech/",
    SCRAP_URL: "https://devapi.lupaair.com/",
    MICRO_URL: "https://demo.webhookapi.intemo.tech/",
  },
};

const production: Config = {
  url: {
    API_URL: "https://api.air.intemo.tech/",
    APP_URL: "https://app.intemo.tech/",
    SCRAP_URL: "https://intranetapi.lupaair.com/",
    MICRO_URL: "https://app.webhookapi.intemo.tech/",
  },
};

let envConfig: Config | undefined;
if (process.env.REACT_APP_ENV === "development") {
  envConfig = development;
} else if (process.env.REACT_APP_ENV === "staging") {
  envConfig = staging;
} else if (process.env.REACT_APP_ENV === "main") {
  envConfig = production;
}

export default envConfig!;
